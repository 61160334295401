import React from 'react';

import PageTransition from '../components/PageTransition';
import routes from '../messages/routes';
import ForgotPassword from '../views/ForgotPassword/ForgotPassword';
import { steps } from '../utils/routes';

const ForgotPasswordPage = () => (
  <PageTransition basepath={'/'} showStepper={false} hideMenu>
    <ForgotPassword path={routes[steps.ForgotPassword]} />
  </PageTransition>
);

ForgotPasswordPage.propTypes = {};

export default ForgotPasswordPage;
