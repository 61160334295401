import { defineMessages } from 'react-intl';

export default defineMessages({
  seoTitle: {
    id: 'cc4.seo.sign-up.title',
    defaultMessage: '',
  },
  seoDescription: {
    id: 'cc4.seo.sign-up.description',
    defaultMessage: '',
  },
  headerSignUp: {
    id: 'cc4.form.sign-up.header-sign-up',
    defaultMessage: 'Sign up to view and manage your claim',
  },
  headerSignIn: {
    id: 'cc4.form.sign-in.header-sign-in',
    defaultMessage: 'Sign in to view and manage your claim',
  },
  continueWith: {
    id: 'cc4.form.sign-up.continue-with',
    defaultMessage: 'continue with',
  },
  orUsingYourEmail: {
    id: 'cc4.form.sign-up.or-using-your-email',
    defaultMessage: 'or using your email',
  },
  labelPassword: {
    id: 'cc4.form.sign-up.label-password',
    defaultMessage: 'Password',
  },
  placeholderPassword: {
    id: 'cc4.form.sign-up.placeholder-password',
    defaultMessage: 'Your password',
  },
  placeholderEmail: {
    id: 'cc4.form.sign-up.placeholder-email',
    defaultMessage: 'Your email',
  },
  placeholderCreateNewPassword: {
    id: 'cc4.form.sign-up.placeholder-create-new-password',
    defaultMessage: 'Create new password',
  },
  signUpButton: {
    id: 'cc4.form.sign-up.sign-up-button',
    defaultMessage: 'Sign up',
  },
  loginButton: {
    id: 'cc4.form.sign-up.log-in-button',
    defaultMessage: 'Log in',
  },
  existingAccount: {
    id: 'cc4.form.sign-up.existing-account',
    defaultMessage: 'I already have an account',
  },
  resetPassword: {
    id: 'cc4.form.sign-up.reset-password',
    defaultMessage: 'Forgot password? <cta>Reset it now</cta>',
  },
  warningAccountExists: {
    id: 'cc4.form.sign-up.warning-account-exists',
    defaultMessage:
      'Looks like you already have an account. Please log in to proceed',
  },
  loginErrorMessage: {
    id: 'cc4.form.sign-up.login-error-message',
    defaultMessage:
      'Login account not correct. Please try another one or reset password',
  },
  signUpBack: {
    id: 'cc4.form.sign-up.sign-up-back',
    defaultMessage: 'Back',
  },
  forgotPasswordSeoTitle: {
    id: 'cc4.seo.forgot-password.title',
    defaultMessage: 'Forgot password',
  },
  forgotPasswordSeoDescription: {
    id: 'cc4.seo.forgot-password.description',
    defaultMessage: '',
  },
  forgotPasswordTitle: {
    id: 'cc4.form.forgot-password.title',
    defaultMessage: 'Forgot password?',
  },
  forgotPasswordInfo: {
    id: 'cc4.form.forgot-password.info',
    defaultMessage:
      'Enter email address you used to set up your account and click "Send link". We will send you an email that will allow you to reset your password.',
  },
  forgotPasswordEmailLabel: {
    id: 'cc4.form.forgot-password.label.email',
    defaultMessage: 'What is your email address?',
  },
  forgotPasswordSendLinkButton: {
    id: 'cc4.form.forgot-password.send-link-button',
    defaultMessage: 'Send link',
  },
  forgotPasswordCantFindEmail: {
    id: 'cc4.form.forgot-password.email-not-found',
    defaultMessage: "We can't find an account with that e-mail address.",
  },
  forgotPasswordEmailSent: {
    id: 'cc4.form.forgot-password.email-sent',
    defaultMessage:
      "We've sent you an email with a link that lets you adjust your password.",
  },

  newPasswordSeoTitle: {
    id: 'cc4.form.new-password.seo-title',
    defaultMessage: 'Set new password',
  },
  newPasswordSeoDescription: {
    id: 'cc4.form.new-password.seo-description',
    defaultMessage: '',
  },
  newPasswordTitle: {
    id: 'cc4.form.new-password.title',
    defaultMessage: 'Set new password',
  },
  newPasswordInfo: {
    id: 'cc4.form.new-password.info',
    defaultMessage:
      'Please choose a password to manage and stay up to date with the process of your claim. Passwords must be at least six characters and match confirmation',
  },
  newPasswordLabel: {
    id: 'cc4.form.new-password.label.new-password',
    defaultMessage: 'New password',
  },
  placeholderNewPassword: {
    id: 'cc4.form.new-password.placeholder.new-password',
    defaultMessage: 'News password',
  },
  confirmPasswordLabel: {
    id: 'cc4.form.new-password.label.confirm-password',
    defaultMessage: 'Confirm password',
  },
  placeholderConfirmPassword: {
    id: 'cc4.form.new-password.placeholder.confirm-password',
    defaultMessage: 'Confirm password',
  },
  setPasswordSuccess: {
    id: 'cc4.form.set-password.success',
    defaultMessage: 'You have successfully changed your password.',
  },
  setPasswordFailure: {
    id: 'cc4.form.set-password.failure',
    defaultMessage:
      'The link you use is no longer valid. Request a new link to change your password.',
  },
  setPasswordButton: {
    id: 'cc4.form.set-password.button',
    defaultMessage: 'Set new password',
  },
});
